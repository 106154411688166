import { useState } from "react";
import CustomModal from "../../components/CustomModal";
import { CloseIconBlack, InfoIconWarn, Spinner } from "../../utils/icons";
import { useDispatch } from "react-redux";
import { useLocalStorage } from "hook/useLocalStorage";
import { removeUserWorkspace } from "api/api";
import { setMyWorkSpaces, setWorkSpacesMembers } from "store/reducers/authReducerSlice";
import { useNotification } from "context/notificationContext";
import { useAppSelector } from "store/storeTypes";

interface RemoveUsersModalProps {
  askModal: { open: boolean; id: string };
  setAskModal: (value: { open: boolean; id: string }) => void;
}

const RemoveUsersModal = ({ askModal, setAskModal }: RemoveUsersModalProps) => {
  const dispatch = useDispatch();
  const { localValue } = useLocalStorage("vultron_workspace_id", "");
  const { myWorkSpaces, workspaceMembers } = useAppSelector((store) => store.auth);
  const { setToast } = useNotification();
  const [removingUser, setRemovingUser] = useState(false);

  // remove a user
  const removeUser = () => {
    if (!askModal?.id || removingUser) return;
    setRemovingUser(true);
    removeUserWorkspace({
      member_id: askModal?.id,
    })
      .then((res) => {
        let prevList = [...workspaceMembers];
        prevList = prevList.filter((v) => v?.id !== askModal?.id);
        dispatch(
          setWorkSpacesMembers({
            data: prevList,
            loading: false,
          })
        );
        if (!myWorkSpaces?.workspaces) return;
        let workspacesList = [...myWorkSpaces?.workspaces];
        let index = workspacesList?.findIndex((v) => v.id === localValue);
        workspacesList[index] = {
          ...workspacesList[index],
          number_of_users: workspacesList[index]?.number_of_users - 1,
        };
        dispatch(
          setMyWorkSpaces({
            data: {
              email: myWorkSpaces?.email,
              workspaces: workspacesList,
            },
            loading: false,
          })
        );
        setAskModal({ open: false, id: "" });
        setToast.success({
          msg: "Member removed from the workspace",
        });
      })
      .catch((err) => {
        console.log("Error while removing member from workspace ", err);
        let errResp = err?.response?.data?.error_msg;
        console.log("Error while sending invite ", errResp || err);
        setToast.error({
          title: "Unable to remove member",
          msg: errResp || "There was an error removing the member from the workspace. Please refresh and try again.",
        });
      })
      .finally(() => {
        setRemovingUser(false);
      });
  };

  return (
    <CustomModal
      isOpen={askModal?.open}
      onClose={() => (removingUser ? null : setAskModal({ open: false, id: "" }))}
      className={"w-[100%] max-w-[477px]"}
      fullScreen={false}
    >
      <div className="px-5 py-6">
        <div className="flex items-start">
          <span className="mr-2">
            <InfoIconWarn />{" "}
          </span>
          <p className="text-[16px] font-semibold">Are you sure you want to remove this member?</p>
          <span
            className="p-2 ml-auto cursor-pointer"
            role="button"
            onClick={() => (removingUser ? null : setAskModal({ open: false, id: "" }))}
          >
            <CloseIconBlack width="13" height="13" />
          </span>
        </div>
        <div className="flex items-start mt-5 flex-col">
          <p className="text-[15px]">
            Confirm that you want to remove{" "}
            <span className="font-semibold">
              {" "}
              {workspaceMembers?.find((v) => v?.id === askModal?.id)?.email || ""}{" "}
            </span>{" "}
            from this workspace.
          </p>
        </div>
        <div className="flex items-center justify-end w-full gap-3 mt-8">
          <button
            disabled={removingUser}
            className="border-gray-300 border py-2 px-4 rounded-lg font-normal"
            onClick={() => {
              setAskModal({ open: false, id: "" });
            }}
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 rounded-lg font-normal bg-gray-darkest text-white disabled:opacity-60"
            onClick={() => {
              removeUser();
            }}
            disabled={removingUser}
          >
            {removingUser ? (
              <>
                {" "}
                <span className="mr-1">
                  <Spinner width={18} height={18} />
                </span>{" "}
                Removing
              </>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default RemoveUsersModal;
