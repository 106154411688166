import { useState } from "react";
import CustomModal from "../../components/CustomModal";
import { CloseIconBlack, Spinner } from "../../utils/icons";
import { useDispatch } from "react-redux";
import { validateEmail } from "utils/helpers";
import { useLocalStorage } from "hook/useLocalStorage";
import { inviteInWorkspace } from "api/api";
import { getWorkspaceUsers, setMyWorkSpaces } from "store/reducers/authReducerSlice";
import { useNotification } from "context/notificationContext";
import { useAppSelector } from "store/storeTypes";

interface InviteModalProps {
  inviteModal: boolean;
  setInviteModal: (value: boolean) => void;
}

const InviteUsersModal = ({ inviteModal, setInviteModal }: InviteModalProps) => {
  const dispatch = useDispatch();
  const { localValue } = useLocalStorage("vultron_workspace_id", "");
  const { myWorkSpaces } = useAppSelector((store) => store.auth);
  const { setToast } = useNotification();
  const [email, setEmail] = useState("");
  const [sendingInvite, setSendingInvite] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleCloseInviteModal = () => {
    setInviteModal(false);
    setEmail("");
    setEmailError(false);
  };

  const handleSendInvite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (sendingInvite) return;
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setSendingInvite(true);

    inviteInWorkspace({ email })
      .then(() => {
        setInviteModal(false);
        setToast.success({
          msg: "Invite sent",
        });
        setEmail("");
        setEmailError(false);
        if (!myWorkSpaces?.workspaces) return;
        let workspacesList = [...myWorkSpaces?.workspaces];
        let index = workspacesList?.findIndex((v) => v.id === localValue);
        workspacesList[index] = {
          ...workspacesList[index],
          number_of_users: workspacesList[index]?.number_of_users + 1,
        };
        dispatch(
          setMyWorkSpaces({
            data: {
              email: myWorkSpaces?.email,
              workspaces: workspacesList,
            },
            loading: false,
          })
        );
        dispatch(getWorkspaceUsers());
      })
      .catch((err) => {
        let errResp = err?.response?.data?.error_msg;
        if (err?.response?.status === 401) {
          let toastResp = "Invited email does not have access. Please contact support@vultron.ai for assistance.";
          setToast.warning({
            title: "Access denied",
            msg: toastResp,
          });
        } else if (err?.response?.status === 400) {
          let toastResp = "Invited email does not have access. Please contact support@vultron.ai for assistance.";
          setToast.warning({
            title: "Access denied",
            msg: toastResp,
          });
        } else {
          let toastResp =
            errResp || "There was an issue sending the invite. Please make sure the email is valid and try again.";
          setToast.error({
            title: "Invite failed",
            msg: toastResp,
          });
        }
      })
      .finally(() => {
        setSendingInvite(false);
      });
  };

  return (
    <CustomModal
      fullScreen={false}
      isOpen={inviteModal}
      onClose={() => handleCloseInviteModal()}
      className={"w-[100%] max-w-[477px]"}
    >
      <div className="px-5 py-4 flex items-center">
        <p className="text-[16px] font-semibold">Invite to workspace</p>
        <span className="p-2 ml-auto cursor-pointer" role="button" onClick={handleCloseInviteModal}>
          <CloseIconBlack width="13" height="13" />
        </span>
      </div>
      <form onSubmit={handleSendInvite} autoComplete="off" autoCorrect="off">
        <div className="mt-2 px-5">
          <label className="text-gray-500 mb-2 block text-sm" htmlFor="email">
            Enter their email
          </label>
          <input
            autoFocus
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            readOnly={sendingInvite}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={() => setEmailError(!validateEmail(email))}
            className={`border w-full rounded-md px-3 py-2 text-sm placeholder:text-gray-500 outline-0 ${
              emailError ? "focus:red-500 border-red-500 text-red-500" : "text-black focus:border-gray-darkest"
            }`}
          />
          {emailError ? <p className="text-red-500 mt-1 ml-1 block text-[14px]">Provided email is not valid</p> : null}
        </div>
        <div className="w-full mt-4">
          <div className="px-5 py-4 flex items-center justify-end w-full gap-3">
            <button
              disabled={sendingInvite}
              type="button"
              className="border-gray-300 border py-2 px-4 rounded-md font-normal"
              onClick={handleCloseInviteModal}
            >
              Cancel
            </button>
            <button
              disabled={sendingInvite}
              type="submit"
              className="py-2 px-4 rounded-md font-normal bg-gray-darkest text-white active:bg-gray-darkest disabled:opacity-60"
            >
              {sendingInvite ? (
                <>
                  {" "}
                  <span className="mr-1">
                    <Spinner width={18} height={18} />
                  </span>{" "}
                  Sending Invite
                </>
              ) : (
                "Send Invite"
              )}
            </button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default InviteUsersModal;
