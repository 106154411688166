import { DropdownMenu } from "components/molecules/dropdown-menu";
import { PERMISSION_LEVELS } from "./constants";
import { WorkspaceMember } from "types/User";
import { ChevronDown } from "lucide-react";
import axios from "axios";
import { useNotification } from "context/notificationContext";
import { useAppDispatch } from "store/storeTypes";
import { getWorkspaceUsers } from "store/reducers/authReducerSlice";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";

interface PermissionLevelDropdownMenuProps {
  member: WorkspaceMember;
}

const PermissionLevelDropdownMenu = ({ member }: PermissionLevelDropdownMenuProps) => {
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();

  const handleAdminPermissionChange = async (permissionLevel: number) => {
    triggerConfirm({
      header: "Confirm that you want to make this user an Admin",
      body: "Only the workspace owner can revert this change",
    }).then((proceed) => {
      if (proceed) {
        modifyPermissionLevel(permissionLevel);
      }
    });
  };

  const modifyPermissionLevel = async (value: number) => {
    const data = { member_id: member.id, role: value };
    try {
      await axios.put(`/users/workspace/members/change`, data);
    } catch (err: any) {
      setToast.error({ msg: err.response?.data?.error_msg });
    } finally {
      dispatch(getWorkspaceUsers());
    }
  };

  const DropdownMenuItems = PERMISSION_LEVELS.map((permissionLevel) => ({
    key: permissionLevel.value,
    label: permissionLevel.label,
    onSelect: () =>
      permissionLevel.label === "Admin"
        ? handleAdminPermissionChange(permissionLevel.value)
        : modifyPermissionLevel(permissionLevel.value),
  }));

  return (
    <div className="px-5">
      <DropdownMenu items={DropdownMenuItems}>
        <div className="flex items-center justify-between gap-1 text-sm border border-[#E8E8E8] rounded-md w-[180px] text-left px-2 py-1.5">
          {member.role} <ChevronDown size={15} />
        </div>
      </DropdownMenu>
    </div>
  );
};

export default PermissionLevelDropdownMenu;
