export const INTERNET_LOADING_MESSAGES = [
  "Initiating internet search",
  "Extracting information",
  "Synthesizing insights",
];

export const WRITING_ASSISTANT_LOADING_MESSAGES = [
  "Searching for relevant information",
  "Synthesizing information",
  "Generating response",
];
