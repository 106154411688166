import { useEffect, useState } from "react";
import { CheckMarkGreen, CloseIconSm, Spinner } from "../../utils/icons";
import { useSelector, useDispatch } from "react-redux";
import { lowerString, validateField } from "utils/helpers";
import { useLocalStorage } from "hook/useLocalStorage";
import { updateUserWorkspace } from "api/api";
import { setMyWorkSpaces } from "store/reducers/authReducerSlice";
import { useNotification } from "context/notificationContext";
import { Plus } from "lucide-react";
import InviteUsersModal from "./InviteUsersModal";
import RemoveUsersModal from "./RemoveUsersModal";
import UsersTable from "./UsersTable";

// ------------ Workspace manage -------------
const WorkSpace = () => {
  const dispatch = useDispatch();
  const { localValue } = useLocalStorage("vultron_workspace_id", "");
  const { myWorkSpaces, isLoadingWorkSpaces, currentUser, workspaceMembers } = useSelector((store) => store.auth);
  const [inviteModal, setInviteModal] = useState(false);
  const [askModal, setAskModal] = useState({ open: false, id: "" });
  const { setToast } = useNotification();
  const isAdmin = lowerString(currentUser?.role) === "admin" || lowerString(currentUser?.role) === "owner";
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [updating, setUpdating] = useState(false);
  const [updatingCompany, setUpdatingCompany] = useState(false);
  const [updatingDesc, setUpdatingDesc] = useState(false);
  const [error, setError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [nameUpdated, setNameUpdated] = useState({
    saved: false,
    lastText: "",
  });
  const [companyUpdated, setCompanyUpdated] = useState({
    saved: false,
    lastText: "",
  });
  const [descUpdated, setDescUpdated] = useState({
    saved: false,
    lastText: "",
  });

  // current workspace
  const currentWorkspace = () => {
    return myWorkSpaces?.workspaces?.find((workspace) => workspace?.id === localValue) || {};
  };

  // handle input change
  const handleChange = (e) => {
    if (!isAdmin || isLoadingWorkSpaces || updating) return;
    let { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Update workspace details, company name only
  const handleUpdateCompanyName = (e) => {
    e?.preventDefault();
    if (!isAdmin || isLoadingWorkSpaces || updatingCompany) return;
    if (!validateField(formData.company_name)) {
      setCompanyError("Company name is required");
      return;
    }
    if (formData.company_name?.trim() === companyUpdated?.lastText?.trim()) {
      setCompanyUpdated({
        saved: true,
        lastText: formData?.company_name,
      });
      return;
    }
    setCompanyError("");
    setUpdatingCompany(true);
    setCompanyUpdated({
      saved: false,
      lastText: formData?.company_name,
    });
    updateUserWorkspace({
      company_name: formData?.company_name,
    })
      .then(() => {
        let updatedWorkspace = { ...myWorkSpaces };
        let list = [...updatedWorkspace.workspaces];
        let index = list?.findIndex((v) => v?.id === localValue);
        list[index] = {
          ...list[index],
          company_name: formData?.company_name,
        };
        updatedWorkspace.workspaces = list;
        dispatch(
          setMyWorkSpaces({
            data: updatedWorkspace,
            loading: false,
          })
        );
        setCompanyUpdated({
          saved: true,
          lastText: formData?.company_name,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          setCompanyError("Company already exists. Please try using another name or request to join organization.");
          setCompanyUpdated({
            updated: false,
            lastText: "",
          });
        }
        setToast.error({
          title: "Unable to update workspace",
          msg:
            err?.response?.status === 500
              ? "Company name already exists. Please use a different company name. If you think this is a problem on our side, please reach out to support@vultron.ai for assistance."
              : "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {
        setUpdatingCompany(false);
      });
  };

  // Update workspace details, name only
  const handleUpdateWorkSpaceName = (e) => {
    e?.preventDefault();
    if (!isAdmin || isLoadingWorkSpaces || updating) return;
    if (!validateField(formData.name)) {
      setError("Workspace name is required");
      return;
    }
    if (formData.name?.trim() === nameUpdated?.lastText?.trim()) {
      setNameUpdated({
        saved: true,
        lastText: formData?.name,
      });
      return;
    }
    setError("");
    setUpdating(true);
    setNameUpdated({
      saved: false,
      lastText: formData?.name,
    });
    updateUserWorkspace({
      name: formData?.name,
      // description: formData?.description
    })
      .then(() => {
        let updatedWorkspace = { ...myWorkSpaces };
        let list = [...updatedWorkspace.workspaces];
        let index = list?.findIndex((v) => v?.id === localValue);
        list[index] = {
          ...list[index],
          name: formData?.name,
          // description: formData?.description
        };
        updatedWorkspace.workspaces = list;
        dispatch(
          setMyWorkSpaces({
            data: updatedWorkspace,
            loading: false,
          })
        );
        setNameUpdated({
          saved: true,
          lastText: formData?.name,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          setError("Name already exists in workspace.");
          setNameUpdated({
            updated: false,
            lastText: "",
          });
        }
        setToast.error({
          title: "Unable to update workspace",
          msg:
            err?.response?.status === 500
              ? "Workspace name already exists. Please contract support@vultron.ai for assistance if you believe this is an issue."
              : "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
        console.log("Error while updating a particular workspace name from settings tab. ", err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  // Update workspace description only
  const handleUpdateWorkSpaceDesc = (e) => {
    e?.preventDefault();
    if (!isAdmin || isLoadingWorkSpaces || updatingDesc) return;
    if (formData.description?.trim() === descUpdated?.lastText?.trim()) {
      setDescUpdated({
        saved: true,
        lastText: formData?.description,
      });
      return;
    }
    setUpdatingDesc(true);
    updateUserWorkspace({
      description: formData?.description,
    })
      .then(() => {
        let updatedWorkspace = { ...myWorkSpaces };
        let list = [...updatedWorkspace.workspaces];
        let index = list?.findIndex((v) => v?.id === localValue);
        list[index] = {
          ...list[index],
          description: formData?.description,
        };
        updatedWorkspace.workspaces = list;
        dispatch(
          setMyWorkSpaces({
            data: updatedWorkspace,
            loading: false,
          })
        );
        setDescUpdated({
          saved: true,
          lastText: formData?.description,
        });
      })
      .catch((err) => {
        setToast.error({
          title: "Unable to update workspace",
          msg: "We were unable to update the workspace due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
        });
      })
      .finally(() => {
        setUpdatingDesc(false);
      });
  };

  //
  useEffect(() => {
    setFormData(currentWorkspace());
  }, [myWorkSpaces]);

  console.log(workspaceMembers, "<<<< WORKSPACE MEMBS");

  //
  return (
    <div className="py-3">
      <div className="bg-white p-5">
        <h4 className="font-medium text-base mb-4 flex items-center">
          Company Information
          {isAdmin ? (
            <span className="mx-2 flex items-center justify-end text-[13px] bg-slate-300 py-3 px-2 rounded-md text-gray-500 font-semibold leading-[0]">
              Admin
            </span>
          ) : null}
        </h4>
        {isAdmin ? (
          <div className={`mt-8 mb-4`}>
            <form onSubmit={handleUpdateWorkSpaceName} className={`${updating ? " opacity-60" : ""}`}>
              <label className="text-gray-500 mb-2 block text-sm" htmlFor="name">
                Workspace
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData?.name}
                  disabled={updating}
                  placeholder="Acme Team"
                  onChange={(e) => {
                    if (e.target.value.length > 140) return;
                    handleChange(e);
                    setError(validateField(e.target.value) ? "" : "Workspace name is required");
                    setNameUpdated({
                      ...nameUpdated,
                      saved: nameUpdated?.lastText?.trim() === e.target.value?.trim(),
                    });
                  }}
                  onBlur={handleUpdateWorkSpaceName}
                  className={`w-full text-sm placeholder:text-gray-500 outline-0 ${
                    error ? "text-red-500 border-red-500" : "text-[#1e1e1e] focus:border-gray-darkest"
                  } px-3 py-2 rounded-md border`}
                />
                {updating ? (
                  <div className="absolute flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-md w-[35px]">
                    <Spinner classes="!text-black" />
                  </div>
                ) : error || nameUpdated?.saved || nameUpdated?.lastText?.trim() === formData?.name?.trim() ? (
                  <div className="absolute  flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-md w-[35px]">
                    {error ? (
                      <span className="bg-red-500 p-[5px] rounded-full text-white">
                        <CloseIconSm width={10} height={10} />
                      </span>
                    ) : (
                      <CheckMarkGreen />
                    )}
                  </div>
                ) : null}
              </div>
              {error ? <p className="text-red-500 mt-1 mb-2 block text-[14px]">{error}</p> : null}
            </form>
            <form onSubmit={handleUpdateCompanyName} className={`${updatingCompany ? " opacity-60" : ""}`}>
              <label className="text-gray-500 text-sm mt-6 mb-2 block" htmlFor="name">
                Company
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={formData?.company_name}
                  disabled={updatingCompany}
                  placeholder="Acme Inc."
                  onChange={(e) => {
                    if (e.target.value.length > 100) return;
                    handleChange(e);
                    setCompanyError(validateField(e.target.value) ? "" : "Company name is required");
                    setCompanyUpdated({
                      ...companyUpdated,
                      saved: companyUpdated?.lastText?.trim() === e.target.value?.trim(),
                    });
                  }}
                  onBlur={handleUpdateCompanyName}
                  className={`w-full text-sm placeholder:text-gray-500 outline-0 ${
                    companyError ? "text-red-500 border-red-500" : "text-border-gray-darkest focus:border-gray-darkest"
                  } px-3 py-2 rounded-md border`}
                />
                {updating ? (
                  <div className="absolute flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                    <Spinner classes="!text-black" />
                  </div>
                ) : companyError ||
                  companyUpdated?.saved ||
                  companyUpdated?.lastText?.trim() === formData?.company_name?.trim() ? (
                  <div className="absolute  flex justify-center items-center bg-white z-[1] px-1 right-[2px] top-[2px] h-[90%] rounded-lg w-[35px]">
                    {companyError ? (
                      <span className="bg-red-500 p-[5px] rounded-full text-white">
                        <CloseIconSm width={10} height={10} />
                      </span>
                    ) : (
                      <CheckMarkGreen />
                    )}
                  </div>
                ) : null}
              </div>
              {companyError ? <p className="text-red-500 mt-1 mb-2 block text-[14px]">{companyError}</p> : null}
            </form>
            <form onSubmit={handleUpdateWorkSpaceDesc} className={`${updatingDesc ? " opacity-60" : ""}`}>
              <label className="text-gray-500 text-sm mb-2 mt-6 block" htmlFor="description">
                Company Overview
              </label>
              <div className="relative">
                <textarea
                  rows={3}
                  onKeyDown={(e) => e.code === "Enter" && !e.shiftKey && handleUpdateWorkSpaceDesc(e)}
                  name="description"
                  id="description"
                  placeholder="Acme Inc. provides a secure communication system with high-level encryption for government agencies, ensuring efficient and confidential data exchange."
                  value={formData?.description}
                  disabled={updatingDesc}
                  onChange={handleChange}
                  onBlur={handleUpdateWorkSpaceDesc}
                  className={`w-full min-h-[100px] mb-3 text-sm text-gray-darkest placeholder:text-gray-500 outline-0 focus:border-gray-darkest px-3 py-3 rounded-md border`}
                />
              </div>
            </form>
          </div>
        ) : (
          <div className={`mt-8 mb-4 ${updating ? " opacity-60" : ""}`}>
            <p className="text-gray-500 text-sm mb-2 block">Workspace</p>
            <p className="w-full mb-6 text-sm text-gray-darkest placeholder:text-gray-500">{formData?.name || "-"}</p>
            <p className="text-gray-500 text-sm mb-2 block">Company</p>
            <p className="w-full mb-6 text-sm text-gray-darkest placeholder:text-gray-500">
              {formData?.company_name || "-"}
            </p>
            <p className="text-gray-500 text-sm mb-2 block">Company Overview</p>
            <p className="w-full mb-11 text-sm text-gray-darkest placeholder:text-gray-500">
              {formData?.description || "-"}
            </p>
          </div>
        )}
        <div className="flex justify-between items-center">
          <p className="font-medium text-base pl-1">Members</p>
          {isAdmin ? (
            <button
              onClick={() => {
                setInviteModal(true);
              }}
              className="px-3 py-2 bg-gray-darkest text-white font-primary-font text-sm rounded-md items-center flex justify-center"
            >
              <Plus size={16} className="mr-2" />
              Add Member
            </button>
          ) : null}
        </div>
        <UsersTable setAskModal={setAskModal} />
      </div>
      <InviteUsersModal inviteModal={inviteModal} setInviteModal={setInviteModal} />
      <RemoveUsersModal askModal={askModal} setAskModal={setAskModal} />
    </div>
  );
};

export default WorkSpace;
