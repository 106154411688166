import { formateUsersList } from "utils/helpers";
import { useAppSelector } from "store/storeTypes";
import PermissionLevelDropdownMenu from "./PermissionLevelDropdownMenu";

interface UsersTableProps {
  setAskModal: (value: { open: boolean; id: string }) => void;
}

// ------------ Workspace manage -------------
const UsersTable = ({ setAskModal }: UsersTableProps) => {
  const { currentUser, workspaceMembers } = useAppSelector((store) => store.auth);
  const canModifyPermissions = currentUser?.role === "Admin" || currentUser?.role === "Owner";

  return (
    <>
      <div className="max-w-full overflow-x-visible border bg-white mt-5 mb-3">
        <table className="table-auto w-full text-[#1e1e1e] rounded-t-md">
          <thead className="border-b text-[#1e1e1e] text-sm">
            <tr className="text-left bg-gray-50">
              <th className="px-5 py-1 font-medium">Members</th>
              <th className="px-4 py-1 font-medium">
                <div className="pl-4 py-2 border-gray-300">Email</div>
              </th>
              <th className="px-4 py-1 font-medium">
                <div className="pl-4 py-2 border-gray-300">Access</div>
              </th>
              {canModifyPermissions ? (
                <th className="px-4 py-1 font-medium">
                  <div className="py-2 border-gray-300 px-4">Action</div>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {formateUsersList(workspaceMembers, currentUser, "id")?.map((member, i) => (
              <tr
                key={i}
                className={`text-left text-sm bg-white ${workspaceMembers?.length === i + 1 ? "" : "border-b"}`}
              >
                <td className="px-5 py-3.5">{member?.username || "-"}</td>
                <td className="px-4 py-3">
                  <div className="px-5">{member?.email || "-"}</div>
                </td>
                <td className="px-4 py-3 w-[210px]">
                  {canModifyPermissions && member?.role !== "Owner" && member?.role !== "Admin" ? (
                    <PermissionLevelDropdownMenu member={member} />
                  ) : (
                    <div className="px-5">{member?.role || "-"}</div>
                  )}
                </td>
                {canModifyPermissions ? (
                  <td className="px-4 py-1 w-[150px]">
                    {member?.id !== currentUser?.id ? (
                      <button
                        onClick={() => setAskModal({ open: true, id: member?.id })}
                        className="text-red-500 mx-auto py-2 px-4  border-0 outline-none cursor-pointer select-none"
                      >
                        Remove
                      </button>
                    ) : null}
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UsersTable;
