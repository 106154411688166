import federalCompassLogo from "../../Assets/federal_compass_logo.jpeg";

export const PARTNER_NAME_TO_LOGO = {
  "Federal Compass": federalCompassLogo,
};

export const PERMISSION_LEVELS = [
  { label: "Admin", value: 1 },
  { label: "Standard", value: 0 },
  { label: "Limited Contributor", value: 3 },
];
