import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { Empty } from "components/molecules/empty";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { useLayoutEffect, useRef, useState, useEffect, useMemo, useCallback } from "react";
import { ArrowDown, ChevronUp, ChevronDown } from "lucide-react";
import { renderBlock } from "./utils";
import { setAiAssistantState, setAssistantPrompt } from "store/reducers/ai-assistant/aiAssistantReducer";
import { fetchAIAssistantFollowUps } from "api/api";
import { FollowUpBlock } from "components/copilot/writing-assistant-drawer/FollowUpBlock";
import { AssistantBlockType, VultronBlock } from "components/copilot/CopilotSchemaImmutableTypes";
import { motion, AnimatePresence } from "framer-motion";

type FollowUp = {
  title: string;
  prompt: string;
  confidence: number;
};

const AIAssistantConversation = () => {
  const { streamState, autoscroll, activeSession } = useAppSelector((store) => store.aiAssistantState);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const scrollRef = useRef<HTMLDivElement>(null);
  const myConversation = activeSession?.conversation;
  const dispatch = useAppDispatch();

  const [followUps, setFollowUps] = useState<FollowUp[]>([]);
  const [showFollowUps, setShowFollowUps] = useState(true);
  const [isLoadingFollowUps, setIsLoadingFollowUps] = useState(false);

  const isEmpty = useMemo(() => !myConversation?.length, [myConversation]);
  const lastMessage = useMemo(() => myConversation?.[myConversation.length - 1], [myConversation]);
  const isLastMessageSuccessful = useMemo(() => {
    return (
      lastMessage?.type === AssistantBlockType.VultronBlock &&
      !(lastMessage as VultronBlock).error &&
      !isStreamingInProgress
    );
  }, [lastMessage, isStreamingInProgress]);

  useLayoutEffect(() => {
    const scrollToBottom = () => {
      scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
      dispatch(setAiAssistantState({ autoscroll: true }));
    };

    if (autoscroll) {
      setTimeout(scrollToBottom, 0);
    }
  }, [autoscroll, activeSession?.id, dispatch, isStreamingInProgress, streamCopy, blockId, isLoadingFollowUps]);

  const messageIdsRef = useRef<string[]>(myConversation?.map((msg) => msg.id) || []);

  useEffect(() => {
    const currentMessageIds = myConversation?.map((msg) => msg.id) || [];
    const prevMessageIds = messageIdsRef.current;

    const newMessageAdded = currentMessageIds.some((id) => !prevMessageIds.includes(id));

    if (
      !myConversation ||
      isLastMessageSuccessful ||
      isLoadingFollowUps ||
      !newMessageAdded ||
      !lastMessage?.body.length
    )
      return;

    const getFollowUps = async () => {
      setIsLoadingFollowUps(true);
      try {
        const response = await fetchAIAssistantFollowUps({
          past_messages: myConversation.map(({ body, type, updated_at, sources }) => ({
            content: body,
            is_assistant: type === AssistantBlockType.VultronBlock,
            sent_at: updated_at,
            sources: type === AssistantBlockType.VultronBlock ? sources : [],
          })),
        });
        setFollowUps(response.data.options);
      } catch (error) {
        console.error("Error fetching follow-ups:", error);
      } finally {
        setIsLoadingFollowUps(false);
      }
    };

    getFollowUps();

    messageIdsRef.current = currentMessageIds;
  }, [isStreamingInProgress, isLastMessageSuccessful, myConversation, isLoadingFollowUps]);

  const handleScroll = useCallback(
    (e: { currentTarget: { scrollTop: number; scrollHeight: number; clientHeight: number } }) => {
      const shouldAutoScroll =
        e.currentTarget.scrollTop + 5 >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
      if (shouldAutoScroll !== autoscroll) {
        dispatch(setAiAssistantState({ autoscroll: shouldAutoScroll }));
      }
    },
    [autoscroll, dispatch]
  );

  const handleFollowUpClick = (prompt: string) => {
    dispatch(setAssistantPrompt(prompt));

    setFollowUps((prevFollowUps) => prevFollowUps.filter((followUp) => followUp.prompt !== prompt));
  };

  return (
    <div
      className="flex w-full items-center justify-center h-full overflow-y-auto"
      onScroll={handleScroll}
      ref={scrollRef}
    >
      {!autoscroll && (
        <button
          onClick={() => {
            if (blockId && myConversation?.[myConversation?.length - 1]?.id !== blockId) {
              document
                .getElementById(`assistant-block-${blockId}`)
                ?.scrollIntoView({ block: "start", behavior: "smooth" });
            } else {
              scrollRef.current?.scrollTo({ top: scrollRef.current?.scrollHeight + 5, behavior: "smooth" });
            }
          }}
          className="absolute flex justify-center items-center w-8 h-8 z-[1] rounded-full right-1/2 bottom-6 shadow-sharp-thin text-black text-[20px] bg-layout-gray-light-active backdrop-blur-[2px]"
        >
          {isStreamingInProgress ? (
            <TypingEffect
              css={{
                paddingLeft: 3,
                paddingRight: 0,
                paddingBottom: 6,
                "& > div": tw`bg-black h-1 w-1 mr-[3px]`,
              }}
            />
          ) : (
            <ArrowDown size={14} />
          )}
        </button>
      )}
      {isEmpty && !isStreamingInProgress ? (
        <Empty heading="How can I assist you today?" title="Send a message to get started" />
      ) : (
        <div className="relative w-full h-full mx-4 lg:px-2 lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]">
          <div className="flex flex-col w-full items-start flex-1 gap-8 px-4 pt-5 pb-12">
            {myConversation?.map(renderBlock)}
            {!isStreamingInProgress && (
              <div className="w-full">
                {isLoadingFollowUps ? (
                  <div className="flex flex-row text-sm text-gray-500 p-2">
                    Generating follow-ups
                    <span className="loading-ellipsis" />
                  </div>
                ) : (
                  followUps.length > 0 && (
                    <>
                      <button
                        className="flex items-center justify-between w-full p-2 text-sm font-medium text-gray-700"
                        onClick={() => setShowFollowUps((prev) => !prev)}
                      >
                        {showFollowUps ? "Hide follow-ups" : "Show follow-ups"}
                        {showFollowUps ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
                      </button>
                      <AnimatePresence>
                        {showFollowUps && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="overflow-hidden"
                            onAnimationComplete={() => {
                              scrollRef.current?.scrollTo({
                                top: scrollRef.current.scrollHeight,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <div className="flex flex-col gap-2 mt-2">
                              {followUps.map((followUp, index) => (
                                <FollowUpBlock key={index} followUp={followUp} onClick={handleFollowUpClick} />
                              ))}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIAssistantConversation;
