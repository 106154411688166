/** @jsxImportSource @emotion/react */

import { VultronBlock as ImmutableVultronBlock } from "components/copilot/CopilotSchemaImmutableTypes";
import { useMemo, useState } from "react";
import { INTERNET_LOADING_MESSAGES, WRITING_ASSISTANT_LOADING_MESSAGES } from "./constants";
import InternetAnimation from "Assets/gifs/black-internet-loading.gif";
import AILoadingAnimation from "Assets/gifs/ai-loading.gif";
import AIDocumentLoadingAnimation from "Assets/gifs/ai-document-loading.gif";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";

const VultronBlockLoadingState = ({ block, isStreaming }: { block: ImmutableVultronBlock; isStreaming: boolean }) => {
  const LoadingMessageSet = useMemo(() => {
    if (block.enableInternet) {
      return INTERNET_LOADING_MESSAGES;
    } else {
      return WRITING_ASSISTANT_LOADING_MESSAGES;
    }
  }, [block]);

  const [loadingMessage, setLoadingMessage] = useState(LoadingMessageSet[0]);

  useAnimateLoadingMsg(isStreaming, 6000, LoadingMessageSet, (msg) => setLoadingMessage(msg));

  return (
    <>
      {isStreaming && !block.enableInternet && !block.promptSources?.length && (
        <div className="flex items-center text-sm">
          <img src={AILoadingAnimation} alt="" className="w-[30px] h-[30px]" />
          <div className="text-gray-900 ml-1">{loadingMessage}</div>
          <span className="loading-ellipsis" />
        </div>
      )}
      {isStreaming && !block.enableInternet && !!block.promptSources?.length && (
        <div className="flex items-center text-sm">
          <img src={AIDocumentLoadingAnimation} alt="" className="w-[30px] h-[30px]" />
          <div className="text-gray-900 ml-1">{loadingMessage}</div>
          <span className="loading-ellipsis" />
        </div>
      )}
      {isStreaming && block.enableInternet && (
        <div className="flex items-center text-sm">
          <img src={InternetAnimation} alt="" className="w-[30px] h-[30px]" />
          <div className="text-gray-900 ml-1">{loadingMessage}</div>
          <span className="loading-ellipsis" />
        </div>
      )}
    </>
  );
};
export default VultronBlockLoadingState;
