/** @jsxImportSource @emotion/react */

import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { ExternalLink, SquareMinus } from "lucide-react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { StepValue } from "../../types";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { setActiveDocument, setHighlightedElementId } from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "styles/markdown-styles.css";
import { highlightAndScrollToElement, isInstantDraftStarted } from "../../utils";
import { useSelection } from "../../document-display/SelectionContext";
import { useEffect, useRef, useState } from "react";
import { omit } from "lodash";
import { pluralizeWord } from "utils/string";
import { YJS_OPERATIONS } from "const-values/yjs";

type Props = {
  sectionId: string;
  requirements: ToImmutable<Extraction["compliance_matrix"]>;
};

const RequirementList = ({ requirements, sectionId }: Props) => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const { selectedBlocks, clearSelection } = useSelection();
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);
  const isComplete = extraction?.status === ExtractionStatus.Completed;
  const isImportStep = extraction?.step === StepValue.Review;
  const isReadOnly = isComplete || isImportStep || isInstantDraftStarted(extraction?.instantDraftConfig?.status);
  const dispatch = useAppDispatch();
  const { addAttribution, assignExtractionRequirement, bulkAssignExtractionRequirements } = useExtractionOperations();
  const previousRequirementsRef = useRef(requirements);
  const [newRequirementsMap, setNewRequirementsMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    previousRequirementsRef.current = requirements;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    if (requirements.length < previousRequirementsRef.current.length) {
      previousRequirementsRef.current = requirements;
      return;
    }

    const addedRequirements = requirements.filter(
      (requirement) =>
        !previousRequirementsRef.current?.some(
          (oldRequirement) => oldRequirement.requirement.id === requirement.requirement.id
        )
    );

    setNewRequirementsMap(
      addedRequirements.reduce<Record<string, boolean>>(
        (acc, requirement) => ({ ...acc, [requirement.requirement.id]: true }),
        {}
      )
    );

    setTimeout(
      () =>
        setNewRequirementsMap((prev) =>
          omit(
            prev,
            addedRequirements.map(({ requirement }) => requirement.id)
          )
        ),
      12000
    );

    previousRequirementsRef.current = requirements;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirements?.length]);

  return (
    <div className="flex flex-col border-r border-gray-light markdown-body">
      {!requirements.length && (
        <div className="group/noRequirement">
          <div className="relative group/test border-b border-gray-light text-center text-gray-500 text-sm py-4">
            No requirements
          </div>
          {!!selectedBlocks?.length && !isReadOnly && (
            <>
              <div className="relative group">
                <div className="z-[4] justify-self-center w-fit flex pt-1.5 justify-center absolute left-0 right-0 top-full pointer-events-none opacity-0 group-hover/noRequirement:pointer-events-auto group-hover/noRequirement:opacity-100">
                  <button
                    onClick={() => {
                      if (!extraction?.id) return;
                      const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                      bulkAssignExtractionRequirements(extraction.id, selectedReqIds, sectionId, 0);
                      clearSelection?.();
                    }}
                    className="bg-blue-400 text-white text-xs rounded-md px-2 py-1 shadow-sharp-thin hover:bg-blue-500"
                  >
                    Insert {selectedBlocks.length} {pluralizeWord(selectedBlocks.length, "Requirement")} Here +
                  </button>
                </div>
                <div className="z-[3] flex justify-center absolute left-0 right-0 top-full border-t-4 border-dashed border-blue-400 opacity-0 pointer-events-none group-hover/noRequirement:pointer-events-auto group-hover/noRequirement:opacity-100"></div>
              </div>
            </>
          )}
        </div>
      )}
      {requirements.map((row, i) => {
        const isNewlyAdded = !!newRequirementsMap[row.requirement.id];
        return (
          <div
            id={`requirement-row-${row.requirement.id}`}
            key={row.requirement.id}
            className="relative group/requirementRow border-b border-gray-light"
          >
            {activeDocument?.id !== row.document?.id ? (
              <button
                onClick={() => {
                  if (isReadOnly) return;
                  const doc = documents.find(({ id }) => id === row.document?.id);
                  if (doc) dispatch(setActiveDocument(doc));
                }}
                className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[175px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900  group-hover/requirementRow:opacity-100 hover:bg-gray-300 font-medium"
              >
                <span className="truncate">{row.document?.name}</span>
                <ExternalLink size={14} className="shrink-0" />
              </button>
            ) : (
              !isReadOnly && (
                <button
                  onClick={() => {
                    if (isReadOnly) return;

                    if (extraction?.id && row?.proposal_reference?.section_id) {
                      assignExtractionRequirement(extraction.id, row.requirement.id, row.proposal_reference.section_id);
                      addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_UNASSIGN);
                    }
                  }}
                  className="text-xs z-[1] select-none flex items-center justify-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[90px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-darkest text-gray-200 group-hover/requirementRow:opacity-100 hover:bg-zinc-700"
                >
                  Unassign
                  <SquareMinus size={14} />
                </button>
              )
            )}
            <div
              className="flex peer gap-2 p-1.5 duration-100 items-center cursor-pointer group-hover/requirementRow:bg-gray-100 overflow-x-auto"
              onClick={() => {
                if (!!selectedBlocks?.length && extraction?.id && !isReadOnly) {
                  const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                  bulkAssignExtractionRequirements(extraction.id, selectedReqIds, sectionId, i + 1);
                  clearSelection?.();
                  return;
                }

                if (!row.requirement.element_id || activeDocument?.id !== row.document?.id) return;

                if (highlightedElementId === row.requirement.element_id) {
                  highlightAndScrollToElement(highlightedElementId);
                } else {
                  dispatch(setHighlightedElementId(row.requirement.element_id));
                }
              }}
              css={[
                !!selectedBlocks?.length && !isReadOnly && tw`group-hover/requirementRow:bg-blue-50`,
                activeDocument?.id !== row.document?.id && !isReadOnly && tw`cursor-default`,
                isNewlyAdded && tw`!bg-blue-100 duration-200`,
              ]}
            >
              <Markdown remarkPlugins={[remarkGfm]} className="text-xs text-gray-600 whitespace-pre-line w-full">
                {row.requirement?.content}
              </Markdown>
            </div>
            {!!selectedBlocks?.length && !isReadOnly && (
              <>
                <div className="z-[4] justify-self-center w-fit peer/middle flex pt-1.5 justify-center absolute left-0 right-0 top-full opacity-0 pointer-events-none peer-hover:pointer-events-auto hover:pointer-events-auto peer-hover:opacity-100 hover:opacity-100 hover:z-[4] peer-hover:z-[4]">
                  <button
                    onClick={() => {
                      if (!extraction?.id) return;
                      const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                      bulkAssignExtractionRequirements(extraction.id, selectedReqIds, sectionId, i + 1);
                      clearSelection?.();
                    }}
                    className="bg-blue-400 text-white text-xs rounded-md px-2 py-1 shadow-sharp-thin hover:bg-blue-500"
                  >
                    Insert {selectedBlocks.length} {pluralizeWord(selectedBlocks.length, "Requirement")} Here +
                  </button>
                </div>
                <div className="z-[3] flex justify-center absolute left-0 right-0 top-full border-t-4 border-dashed border-blue-400 opacity-0 pointer-events-none peer-hover:pointer-events-auto peer-hover:opacity-100 peer-hover/middle:opacity-100 peer-hover/middle:pointer-events-auto"></div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RequirementList;
